import React, { useState } from "react";
import OutsideClickHandler from "react-outside-click-handler";
import styled from '@emotion/styled';
import { graphql, useStaticQuery } from "gatsby";
import mediaqueries from "@styles/media";
import { IAuthor } from "@types";
import Icons from '@icons';

import CategoryItem from './Category.Item'
import CategoryAllItem from './Category.All.Item'

interface AuthorHeroProps {
  category: IAuthor;
}

const siteQuery = graphql`
{
  allAuthor(limit: 100) {
    edges {
      node {
        name
        slug
        social {
          url
        }
      }
    }
  }
}
`;

const NavCategory: React.FC<AuthorHeroProps> = ({ }) => {

  const result = useStaticQuery(siteQuery).allAuthor.edges;
  const [isOpen, setIsOpen] = useState(false);

  return (
    <NavContainer>
      <NavControlsDesktop>
        <CategoryAllItem />
        {result.map((category, index) => (
          <CategoryItem name={category.node.name} link={category.node.social} slug={category.node.slug} key={index} />
        ))}
      </NavControlsDesktop>

      <NavControlsMobile>
        <CategoryMenu onClick={() => setIsOpen(!isOpen)} isOpen={isOpen}>
          <MenuEntry>
            <IconWrapper>
              <Icons.AllCategory />
            </IconWrapper>
            <span>Explore all lessons</span>
            <Icons.ToggleOpen fill={"#A1A1A6"} />
          </MenuEntry>

          {isOpen && (
            <OutsideClickHandler onOutsideClick={() => setIsOpen(!isOpen)}>
              <CategoryMenuOpen>
              <CategoryAllItem />
                {result.map((category, index) => (
                  <CategoryItem name={category.node.name} link={category.node.social} slug={category.node.slug} key={index} />
                ))}
              </CategoryMenuOpen>
            </OutsideClickHandler>
          )}
        </CategoryMenu>

      </NavControlsMobile>
    </NavContainer>
  );
};

export default NavCategory;


const NavContainer = styled.div`
  position: relative;
  z-index: 100;
  display: flex;
  justify-content: space-between;
  flex-direction: column;

  ${mediaqueries.tablet`
    padding: 0 2rem;
  `}
`;

const NavControlsDesktop = styled.div`
  position: relative;
  align-items: center;

  ${mediaqueries.desktop_medium`
    display: none;
  `}
`;

const NavControlsMobile = styled.div`
  position: relative;
  align-items: center;
  display: none;

  ${mediaqueries.desktop_medium`
    display: block;
  `}
`;

const CategoryMenu = styled.div<{ isOpen: boolean }>`
  position: relative;
`;

const CategoryMenuOpen = styled.div`
  position: absolute;
  left: -20px;
  right: -20px;
  top: -16px;
  padding: 16px 20px;
  background: rgba(23, 3, 33, 1);
  box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.4);
  border-radius: 0;
  cursor: pointer;

  &:before {
    content: "";
    position: absolute;
    height: 8px;
    top: 0;
    left: 0;
    width: 100%;
    background: ${p => p.theme.colors.accentGradient};
  }
`;

const IconWrapper = styled.div`
  width: 40px;
  height: 40px;
  background-color: ${p => p.theme.colors.card};
  border-radius: 50%;
  transition: background 0.33s var(--ease-out-quart);
  display: grid;
  justify-items: center;
  align-items: center;

  svg {
    scale: 1.2;
  }
`;

const MenuEntry = styled.div`
  display: grid;
  grid-template-columns: 48px 1fr 32px;
  grid-template-rows: 2;
  column-gap: 8px;
  align-items: center;
  font-size: 20px;
  line-height: 24px;
  color: ${p => p.theme.colors.secondary};
  padding: 8px 0;

  &:active {
    background: ${p => p.theme.colors.card};
  }

  &:active ${IconWrapper} {
    background-color: ${p => p.theme.colors.accent};
  }
`;


