import React from 'react';
import styled from '@emotion/styled';

import Icons from '@icons';
import mediaqueries from '@styles/media';

interface SocialLinksProps {
  links: {
    name: string;
    url: string;
  }[];
  fill: string;
}

const icons = {
  branding: Icons.Branding,
  communication: Icons.Communication,
  contentcreating: Icons.ContentCreating,
  graphicdesign: Icons.GraphicDesign,
  marketing: Icons.Marketing,
  motivation: Icons.Motivation,
  productdesign: Icons.ProductDesign,
  storytelling: Icons.StoryTelling,
  typography: Icons.Typography,
  tool: Icons.Tool,
  uidesign: Icons.UIDesign,
  writing: Icons.Writing,
  allcategory: Icons.AllCategory,
  creativity: Icons.Creativity,
  psychology: Icons.Psychology,
};

const getHostname = url => {
  return new URL(url.toLowerCase()).hostname.replace('www.', '').split('.')[0];
};


const CategoryIcon: React.FC<SocialLinksProps> = ({
  links,
  fill
}) => {
  if (!links) return null;

  return (
    <>
      {links.map(option => {
        const name = option.name || getHostname(option.url);
        const Icon = icons[name];
        if (!Icon) {
          throw new Error(
            `unsupported social link name=${name} / url=${option.url}`,
          );
        }
        return (
          <Icon fill={fill} />
        );
      })}
    </>
  );
};

export default CategoryIcon;
