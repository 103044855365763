import React from 'react';
import styled from '@emotion/styled';
import { LinkInternal } from "@components/LinkNav";

import mediaqueries from '@styles/media';

import CategoryIcon from '../../sections/category/Category.Icon';

const CategoryItem = ({name, link, slug}) => {

  return (
    <CategoryItemContainer>
      <LinkInternal to={slug} data-a11y="false" activeClassName="active">
        <LinkWrap>
          <IconWrapper><CategoryIcon links={link} fill="#A1A1A6"/></IconWrapper>
          <LinkName>{name}</LinkName>
        </LinkWrap>
      </LinkInternal>
    </CategoryItemContainer>
  );
};

export default CategoryItem;

const IconWrapper = styled.span`
  width: 40px;
  height: 40px;
  background-color: ${p => p.theme.colors.card};
  border-radius: 50%;
  transition: background 0.33s var(--ease-out-quart);
  display: grid;
  justify-items: center;
  align-items: center;

  svg {
    scale: 1.2;
  }
`;

const LinkName = styled.span`

`;

const CategoryItemContainer = styled.div`
  & a {
    font-size: 20px;
    line-height: 24px;
    color: ${p => p.theme.colors.secondary};
    font-weight: ${p => p.theme.fontsWeight.regular};
    font-family: ${p => p.theme.fonts.body};
    padding: 8px 0;
    margin-right: 24px;
    display: block;
    width: 100%;
    
    ${mediaqueries.tablet`
      margin-right: 16px;
    `}

    &:hover {
      & ${LinkName} {
        ${p => p.theme.textGradient};
      }
    }
    
    &.active  {
      & ${LinkName} {
        ${p => p.theme.textGradient};
      }

      ${IconWrapper} {
        background: ${p => p.theme.colors.accent};
        svg {
          path {
              fill: ${p => p.theme.colors.primary};
          }
        }
      }
      
      &::after {
        height: 0;
      }
    }
  }

`;


const LinkWrap = styled.div`

  display: grid;
  grid-template-columns: 48px 1fr;
  grid-template-rows: 2;
  column-gap: 8px;
  align-items: center;

  &:hover ${IconWrapper} {
    background: ${p => p.theme.colors.accent};

    svg {
      path {
          fill: ${p => p.theme.colors.primary};
      }
    }
  }
`; 
